import React, { useMemo } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import et from 'react-phone-number-input/locale/et.json.js';
import en from 'react-phone-number-input/locale/en.json.js';
import PropTypes from 'prop-types';
import { gettext } from '../../utils/text';

const LOCALES = { et, en };

const PhoneField = ({ field, form, placeholder, inputFieldProps }) => {
    const currentLocale = useMemo(
        () => LOCALES[DJ_CONST.languageCode] || LOCALES.en,
        [DJ_CONST.languageCode],
    );

    return (
        <PhoneInput
            defaultCountry="EE"
            international
            placeholder={placeholder}
            value={form.values[field]}
            labels={currentLocale}
            numberInputProps={inputFieldProps}
            name={field}
            onChange={value => {
                form.setFieldValue(field, value);
            }}
            onBlur={() => {
                if (!form.touched[field]) {
                    form.setFieldTouched(field, true);
                }
            }}
        />
    );
};

PhoneField.propTypes = {
    field: PropTypes.string.isRequired,
    form: PropTypes.shape({
        values: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        touched: PropTypes.object.isRequired,
    }).isRequired,
    placeholder: PropTypes.string,
    inputFieldProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

PhoneField.defaultProps = {
    placeholder: gettext('Phone'),
    inputFieldProps: {},
};

export default PhoneField;
